<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_disposition_type">Filter By Disposition Type</label>
            <v-select
              id="filter_disposition_type"
              v-model="selected.filter_disposition_type"
              :options="filterDispositionTypes"
              label="type_name"
              placeholder="-- Please Select Disposition Type --"
            >
              <template #option="{ type_name }">
                <span>{{ type_name }}</span>
              </template>
              <template #no-options="">
                No Available Disposition Types
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_disposition">Filter By Disposition</label>
            <v-select
              id="filter_disposition"
              v-model="selected.filter_disposition"
              :options="filterDispositions"
              label="disposition_name"
              placeholder="-- Please Select Disposition --"
            >
              <template #option="{ disposition_name }">
                <span>{{ disposition_name }}</span>
              </template>
              <template #no-options="">
                No Available Dispositions
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add campaign
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              :disabled="state.busy"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-campaign-form"
      size="xs"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">

              <ValidationProvider
                #default="{ errors }"
                name="campaign name"
                vid="campaign_name"
                rules="required|max:100"
              >
                <b-form-group>
                  <label for="campaign_name">Campaign</label>
                  <b-input
                    id="campaign_name"
                    v-model="campaign.campaign_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter campaign name"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="disposition type"
                vid="disposition_type"
                rules="required"
              >
                <b-form-group>
                  <label for="disposition_type">Disposition Type</label>
                  <v-select
                    id="disposition_type"
                    v-model="selected.disposition_type"
                    :options="list.disposition_types"
                    type="text"
                    :class="[errors.length > 0 ? 'is-invalid' : '']"
                    label="type_name"
                    placeholder="-- Select Disposition Type--"
                    :disabled="state.busy || state.fetching.disposition_types"
                    :loading="state.fetching.disposition_types"
                  >
                    <template #option="{ type_name }">
                      <div class="d-flex flex-column">
                        <strong>{{ type_name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      No Available Disposition Type
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="disposition"
                vid="disposition"
                rules="required"
              >
                <b-form-group>
                  <label for="disposition">Disposition</label>
                  <v-select
                    id="disposition"
                    v-model="selected.disposition"
                    :options="list.dispositions"
                    type="text"
                    :class="[errors.length > 0 ? 'is-invalid' : '']"
                    label="disposition_name"
                    placeholder="-- Select Disposition --"
                    :disabled="state.busy || state.fetching.dispositions"
                    :loading="state.fetching.dispositions"
                  >
                    <template #option="{ disposition_name }">
                      <div class="d-flex flex-column">
                        <strong>{{ disposition_name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      No Available Disposition
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">

              <ValidationProvider
                #default="{ errors }"
                name="date from"
                vid="date_from"
                rules="required|max:100"
              >
                <b-form-group>
                  <label for="date_from">Date From</label>
                  <b-input
                    id="date_from"
                    v-model="campaign.date_from"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="date to"
                vid="date_to"
                rules="required|max:100"
              >
                <b-form-group>
                  <label for="date_to">Date To</label>
                  <b-input
                    id="date_to"
                    v-model="campaign.date_to"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="target"
                vid="target"
                rules="required|numeric"
              >
                <b-form-group>
                  <label for="target">Target</label>
                  <b-input
                    id="target"
                    v-model="campaign.target"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                    placeholder="enter target"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <div
                v-if="state.editing"
                class="form-group"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="campaign.active"
                  name="is_active"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </div>

            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SupervisorCampaignService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorCampaign',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Campaigns'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        fetching: {
          disposition_types: false,
          dispositions: false,
          filter_dispositions: false
        }
      },
      filter: {
        disposition_type: null,
        disposition: null
      },
      selected: {
        current: null,
        disposition_type: null,
        filter_disposition_type: null,
        disposition: null,
        filter_disposition: null
      },
      list: {
        disposition_types: [],
        dispositions: [],
        filter_dispositions: []
      },
      campaign: {
        id: null,
        disposition_type: null,
        disposition: null,
        date_from: null,
        date_to: null,
        target: 0,
        campaign_name: '',
        active: 1
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          {
            key: 'created_at',
            formatter: this.dateTimeFormatter,
            sortable: true
          },
          { key: 'target' },
          { key: 'campaign_name', label: 'campaign' },
          { key: 'disposition_type.type_name', label: 'disposition type' },
          { key: 'disposition.disposition_name', label: 'disposition' },
          { key: 'active', label: 'IS ACTIVE?' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Campaign' : 'Add Campaign'
    },

    filterDispositionTypes () {
      return this.list.disposition_types
    },

    filterDispositions () {
      return this.list.filter_dispositions
    }
  },

  watch: {
    'selected.filter_disposition_type' (value) {
      this.filter.disposition_type = value?.id || null
      this.getFilterDispositions(this.filter.disposition_type)
      this.onFilterChanged()
    },

    'selected.filter_disposition' (value) {
      this.filter.disposition = value?.id || null
      this.onFilterChanged()
    },

    'selected.disposition_type' (value) {
      this.campaign.disposition_type = value?.id || null
      this.getDispositions(this.campaign.disposition_type)
    },

    'selected.disposition' (value) {
      this.campaign.disposition = value?.id || null
    }
  },

  mounted () {
    core.index()
    this.getDispositionTypes()
  },

  methods: {

    async myProvider (ctx) {
      try {
        const { data } = await SupervisorCampaignService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_disposition_type: this.filter.disposition_type,
            filter_disposition: this.filter.disposition
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    },

    async getDispositionTypes () {
      this.state.fetching.disposition_types = true
      await SharedListService.getDispositionTypes().then(({ data }) => {
        this.list.disposition_types = data
        this.state.fetching.disposition_types = false
      })
    },

    async getDispositions (dispositionType) {
      this.selected.disposition = null
      this.state.fetching.dispositions = true
      if (dispositionType) {
        await SharedListService.getDispositions(`disposition_type_id=${dispositionType}`).then(({ data }) => {
          this.list.dispositions = data
          this.state.fetching.dispositions = false
          if (this.state.editing) {
            this.selected.disposition = {
              id: this.selected.current.disposition.id,
              disposition_name: this.selected.current.disposition.disposition_name
            }
            this.selected.current.disposition = null
          }
        })
      } else {
        this.state.fetching.dispositions = false
        this.list.dispositions = []
      }
    },

    async getFilterDispositions (dispositionType) {
      this.selected.filter_disposition = null
      this.state.fetching.filter_dispositions = true
      if (dispositionType) {
        await SharedListService.getDispositions(`disposition_type_id=${dispositionType}`).then(({ data }) => {
          this.list.filter_dispositions = data
          this.state.fetching.filter_dispositions = false
        })
      } else {
        this.state.fetching.filter_dispositions = false
        this.list.filter_dispositions = []
      }
    },

    onShowAddForm () {
      this.state.editing = false
      this.selected.disposition_type = null
      this.selected.disposition = null
      this.campaign.id = null
      this.campaign.active = 1
      this.campaign.disposition_type = null
      this.campaign.disposition = null
      this.campaign.date_from = null
      this.campaign.date_to = null
      this.campaign.target = 0
      this.campaign.campaign_name = ''
      this.$bvModal.show('modal-campaign-form')
    },

    onShowEditForm (current) {
      this.selected.current = { ...current }
      this.state.editing = true
      this.campaign.id = current.id
      this.campaign.disposition_type = current.disposition_type
      this.campaign.disposition = current.disposition
      this.campaign.campaign_name = current.campaign_name
      this.campaign.active = current.active
      this.campaign.date_from = current.date_from
      this.campaign.date_to = current.date_to
      this.campaign.target = current.target

      if (current.disposition_type) {
        this.selected.disposition_type = {
          id: current.disposition_type.id,
          type_name: current.disposition_type.type_name
        }
      }

      this.$bvModal.show('modal-campaign-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create campaign?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        SupervisorCampaignService.post(this.campaign).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-campaign-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        SupervisorCampaignService.put(this.campaign).then(({ data }) => {
          this.$bvModal.hide('modal-campaign-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.campaign.id })
            row.created_at = data.campaign.created_at
            row.active = data.campaign.active
            row.target = data.campaign.target
            row.date_from = data.campaign.date_from
            row.date_to = data.campaign.date_to
            row.campaign_name = data.campaign.campaign_name
            row.disposition_type = data.campaign.disposition_type
            row.disposition = data.campaign.disposition
            row.updated_at = data.campaign.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
